import * as ko from "knockout";
import KoCodemirrorComponent from "@app/components/ko-codemirror";
import KoMarkedComponent from "@app/components/ko-marked";
import WikiNavbarComponent from "@app/wiki/components/wiki-navbar/wiki-navbar";
import Page from "@app/wiki/models/page";
import WikiAppView from "./wiki-app.html";

import "@app/wiki/assets/fontello/fontello-embedded.css";
import rs from '@app/remotestorage/remotestorage';

export enum Mode {
  MARKDOWN,
  EDITOR,
  BOTH,
};

export class WikiAppViewModel {
  public currentPath: ko.Observable<string> = ko.observable();
  public currentPage: ko.Observable<Page> = ko.observable();
  public dirListing: ko.ObservableArray<string> = ko.observableArray();

  public isMobile: ko.Observable<boolean>;
  public mode: ko.Observable<number>;
  public isEditorVisible: ko.Computed<boolean>;
  public isMarkdownVisible: ko.Computed<boolean>;

  private setDir(path: string) {
    rs.pages.list(path)
      .then((listing: string[]) => {
        this.currentPage(null);
        this.dirListing(listing);
      });
  }

  private setPage(path: string) {
    rs.pages.read(path).then((page: any) => {
      this.dirListing(null);
      if (page) {
        // page exists
        this.currentPage(new Page(page));
      } else {
        // page missing
        if (path == "index") {
          // create default /index
          rs.pages.write({ id: "/index", content: "# Hello World" })
            .then(() => this.setPage("index"));
        } else {
          // create empty page
          this.currentPage(new Page({id: "/" + path}));
        }
      }
    });
  }

  private redirectRelativePath(relPath: string) {
    let relPathNodes = relPath.split("/");
    let curPathNodes = this.currentPath().split("/").slice(0, -1);
    let newPathNodes: Array<string> = [];
    relPathNodes.forEach((relNode) => {
      switch (relNode) {
        case "..":
          if (curPathNodes.length > 1)
            curPathNodes = curPathNodes.slice(0, -1);
          break;
        case ".":
          break;
        default:
          newPathNodes.push(relNode);
      }
    });
    window.location.hash = curPathNodes.concat(newPathNodes).join("/");
  }

  private route() {
    let path = window.location.hash.substring(1);
    if (!path.startsWith("/")) {
      this.redirectRelativePath(path);
      return;
    }
    this.currentPath(path);
    if (path.endsWith("/")) {
      // directory
      this.setDir(path.substring(1))
    } else {
      // page
      this.setPage(path.substring(1));
    }
  }

  constructor() {
    // register components
    ko.components.register('ko-codemirror', KoCodemirrorComponent);
    ko.components.register('ko-marked', KoMarkedComponent);
    ko.components.register('wiki-navbar', WikiNavbarComponent);

    // initialize routing
    if (!window.location.hash) window.location.hash = "#/index";
    this.route();

    window.addEventListener("hashchange", () => {
      this.route();
    });

    this.currentPage.subscribe((page: Page) => {
      if (page) {
        page.content.subscribe(() => {
          rs.pages.write(ko.toJS(page));
        });
      }
    });

    // layout
    this.isMobile = ko.observable(window.innerWidth < 768);
    let resizeTimeout: any = null;
    window.addEventListener("resize", () => {
      if (resizeTimeout != null) return;
      resizeTimeout = setTimeout(() => {
        this.isMobile(window.innerWidth < 768);
        if (this.isMobile() && this.mode() == Mode.BOTH) this.mode(Mode.EDITOR);
        clearTimeout(resizeTimeout);
        resizeTimeout = null;
      }, 500);
    });

    this.mode = ko.observable(this.isMobile() ? Mode.MARKDOWN : Mode.BOTH);
    this.isMarkdownVisible = ko.computed(() => (this.mode() == Mode.MARKDOWN || this.mode() == Mode.BOTH));
    this.isEditorVisible = ko.computed(() => (this.mode() == Mode.EDITOR || this.mode() == Mode.BOTH));
  }
}

export default {
  template: WikiAppView,
  viewModel: WikiAppViewModel
}
