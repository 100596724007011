import * as ko from "knockout";

export default class Page {
  id: ko.Observable<string>;
  content: ko.Observable<string>;

  constructor(page: {id: string, content?: string}) {
    this.id = ko.observable(page.id);
    this.content = ko.observable(page.content || "").extend({rateLimit: 500});
  }
}
