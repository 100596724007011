const Pages = { name: 'pages', builder: function(privateClient: any, publicClient: any) {
  privateClient.declareType('page', {
    "type": "object",
    "properties": {
      "id": {
        "type": "string"
      },
      "content": {
        "type": "string"
      },
    },
    "required": [ "id", "content" ]
  });

  return {
    exports: {
      list: function(path: string) {
        return privateClient.getListing(path)
          .then((listing: any) => Object.keys(listing));
      },

      write: function(page: any) {
        return privateClient.storeObject("page", page.id.substring(1), page);
      },

      read: function(path: string) {
        return privateClient.getObject(path);
      }
    }
  }
}};

export default Pages;
