var CodeMirror = require("codemirror");
import 'codemirror/addon/display/placeholder';
import 'codemirror/addon/edit/continuelist';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/gfm/gfm';

export class KoCodemirrorViewModel {
  private editor: any;

  constructor(params: {content: ko.Observable<string>, visible: ko.Observable<boolean>}) {
    // initialize editor
    var editorElem = document.getElementById("editor");
    this.editor = CodeMirror.fromTextArea(editorElem, {
      mode: "gfm",
      lineWrapping: true,
      extraKeys: {"Enter": "newlineAndIndentContinueMarkdownList"},
    });
    this.editor.setValue(params.content());

    // register callbacks
    params.content.subscribe((newContent) => {
      if (newContent !== this.editor.getValue())
        this.editor.setValue(newContent);
    });

    this.editor.on("change", () => {
      params.content(this.editor.getValue());
    });

    params.visible.subscribe(visible => { if (visible) this.editor.refresh() });
  }
}

export default {
  template: '<textarea id="editor" placeholder="This page does not exist. Write something here to create it."></textarea>',
  viewModel: KoCodemirrorViewModel
}
