import * as ko from "knockout";
import { WikiAppViewModel, Mode } from "@app/wiki/components/wiki-app/wiki-app";
import WikiNavbarView from "./wiki-navbar.html";
import "./wiki-navbar.css";

export class WikiNavbarViewModel {
  private wiki: WikiAppViewModel;

  private pageTitle: ko.Computed<string>;
  private breadcrumb: ko.Computed<string>;
  private nextMode: ko.Computed<number>;
  private toggleValue: ko.Computed<string>;

  toggleClicked(): void {
    this.wiki.mode(this.nextMode());
  }

  constructor(params: {wiki: WikiAppViewModel}) {
    this.wiki = params.wiki;

    this.pageTitle = ko.computed(() => {
      if (this.wiki &&
        this.wiki.currentPage() &&
        this.wiki.currentPage().content()) {
        let title = this.wiki.currentPage().content().split("\n")[0];
        if (title.startsWith("# ")) title = title.substring(2);
        return title;
      }
      return "";
    });

    this.breadcrumb = ko.computed(() => {
      if (!this.wiki || !this.wiki.currentPath()) return null;
      let html = "";
      let elements = this.wiki.currentPath().split("/");
      elements.forEach((elem, index) => {
        let elemPath = elements.slice(0, index + 1).join("/");
        let slash = index < elements.length - 1 ? "/" : "";
        html += `<a class="wiki-navbar-bc-sep" href="#${elemPath}${slash}">${elem}${slash}</a>`;
      });
      return html;
    });

    this.nextMode = ko.computed(() => {
      let maxMode: number = this.wiki.isMobile() ? Mode.EDITOR : Mode.BOTH;
      let nextMode = this.wiki.mode() + 1;
      if (nextMode > maxMode) nextMode = 0;
      return nextMode;
    });

    this.toggleValue = ko.computed(() => {
      return "icon-" + ["eye", "edit", "columns"][this.nextMode()];
    });

  }
}

export default {
  template: WikiNavbarView,
  viewModel: WikiNavbarViewModel
}
