import * as ko from "knockout";
import * as OfflinePluginRuntime from 'offline-plugin/runtime';

import AppComponent from "./app";
import WikiAppComponent from "./wiki/components/wiki-app/wiki-app"

OfflinePluginRuntime.install({
  onUpdateReady: () => {
    console.log("SW: Update available. Updating...");
    OfflinePluginRuntime.applyUpdate()
  },
  onUpdated: () => {
    console.log("SW: Updated. Reloading...");
    window.location.reload()
  },
  onUpdateFailed: () => { console.log("SW: Update failed."); }
});

const components: any = {
  "app": AppComponent,
  "wiki-app": WikiAppComponent,
}

for (let tag in components) {
  ko.components.register(tag, components[tag]);
}

ko.applyBindings(WikiAppComponent);
