import * as ko from "knockout";
var Marked = require("marked");

export class KoMarkedViewModel {
  private parsed: ko.Computed<string>;

  constructor(params: {markdown: ko.Observable<string>}) {
    // initialize markdown parser
    this.parsed = ko.computed(() => {
      if (params.markdown && params.markdown())
        return Marked(params.markdown());
    });
  }
}

export default {
  template: '<div data-bind="html: parsed"></div>',
  viewModel: KoMarkedViewModel
}
